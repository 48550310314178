// src/SchoolList.js
// 아직 pay 처리 전 학교 리스트 : admin 처리예정 리스트
// firestore login 비밀번호 등록, url 지정
import React, { useState, useEffect } from "react";
import { firestore, db, auth } from "../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { ref, update, set } from "firebase/database";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "./School.css";

const SchoolList = () => {
  const [processing, setProcessing] = useState(false);
  const [schools, setSchools] = useState([]);

  // StartC 고객센터에서 결제 전 리스트 확인
  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const q = query(
          collection(firestore, "school"),
          where("pay", "==", false)
        );
        const querySnapshot = await getDocs(q);
        const schoolData = [];
        querySnapshot.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          const date = new Date(
            data.createdAt.seconds * 1000 + data.createdAt.nanoseconds / 1000000
          );
          schoolData.push({ id: docSnapshot.id, ...data, createdAt: date });
        });
        setSchools(schoolData);
      } catch (error) {
        console.error("Error fetching schools: ", error);
      }
    };

    fetchSchools();
  }, []);

  function padNumber(base, number) {
    const baseLength = base.length;
    const paddedNumber = number.toString().padStart(baseLength, "0");
    return paddedNumber;
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleProcessPayments = async (
    schoolId,
    admName,
    stdName,
    schoolName,
    fullDomain,
    schoolCode,
    contactEmail,
    numberOfTeachers,
    numberOfStudents
  ) => {
    setProcessing(true);
    try {
      // groups 학교코드 등록 : 0000은 초기 사용불가 -> 0010이 Study 10 설정 TODO
      await update(ref(db, `groups`), {
        [schoolCode]: "0000",
      });

      const dayCount = 100;
      const millisecondsInADay = 86400000;
      const currentDate = new Date();
      const futureDate = new Date(
        currentDate.getTime() + dayCount * millisecondsInADay
      );
      const startDate = formatDate(currentDate);
      const endDate = formatDate(futureDate);
      const startat = currentDate.getTime();
      const startatend = futureDate.getTime();

      await update(ref(db, `teams/${schoolCode}`), {
        "000": { headcount: numberOfStudents + numberOfTeachers },
        adminemail: contactEmail,
        date1: startDate,
        date2: endDate,
        groupname: schoolName,
        headcount: numberOfStudents + numberOfTeachers,
        headlimit: numberOfStudents + numberOfTeachers,
        startAt: startat,
        startAtdays: dayCount,
        startAtend: startatend,
      });

      for (let i = 0; i < numberOfTeachers; i++) {
        const teacherEmail = `${admName}${padNumber(
          numberOfTeachers.toString(),
          i + 1
        )}@${fullDomain}`;

        const teacherName = `${admName}${padNumber(
          numberOfTeachers.toString(),
          i + 1
        )}`;

        const teacherPassword = "99999999";

        console.error("teacherEmail: ", teacherEmail);

        const userCredential = await createUserWithEmailAndPassword(
          auth,
          teacherEmail,
          teacherPassword
        );
        const user = userCredential.user;
        await set(ref(db, `users/${user.uid}`), {
          Data: {
            prograss: "00000000000000000000",
            ss: {
              STUDY0:
                "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
            },
          },
          coin: "0",
          email: teacherEmail,
          expireStat: 0,
          group: schoolCode,
          level: "0",
          loginCnt: 0,
          loginTime: 0,
          name: teacherName,
          startAt: startat,
          startAtdays: dayCount,
          startAtend: startatend,
          team: "000",
          usertype: "t",
        });
        await set(ref(db, `teams/${schoolCode}/000/members/${user.uid}`), true);
      }

      for (let i = 0; i < numberOfStudents; i++) {
        const studentEmail = `${stdName}${padNumber(
          numberOfStudents.toString(),
          i + 1
        )}@${fullDomain}`;

        const studentName = `${stdName}${padNumber(
          numberOfStudents.toString(),
          i + 1
        )}`;
        const studentPassword = "12345678";

        console.error("studentEmail: ", studentEmail);

        const userCredential = await createUserWithEmailAndPassword(
          auth,
          studentEmail,
          studentPassword
        );
        const user = userCredential.user;
        await set(ref(db, `users/${user.uid}`), {
          Data: {
            prograss: "00000000000000000000",
            ss: {
              STUDY0:
                "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
            },
          },
          coin: "0",
          email: studentEmail,
          expireStat: 0,
          group: schoolCode,
          level: "0",
          loginCnt: 0,
          loginTime: 0,
          name: studentName,
          startAt: startat,
          startAtdays: dayCount,
          startAtend: startatend,
          team: "000",
          usertype: "0",
        });
        await set(ref(db, `teams/${schoolCode}/000/members/${user.uid}`), true);
      }

      const schoolDocRef = doc(firestore, "school", schoolId);
      await updateDoc(schoolDocRef, { accountsCreated: true, pay: true });
      alert("Payments processed and accounts created");
      setSchools((prevSchools) =>
        prevSchools.filter((school) => school.id !== schoolId)
      );
    } catch (error) {
      console.error("Error processing payments: ", error);
    }
    setProcessing(false);
  };

  return (
    <div>
      <h2>StartC 고객센터 : 결제 후 그룹/팀/계정 생성</h2>
      <ol>
        {schools.map((school) => (
          <li key={school.id}>
            <div className="card">
              <div className="card-body">
                <div className="school-info">
                  {school.schoolName} (학교코드 : {school.schoolCode})
                </div>
                <div className="text-primary">
                  <ol>
                    <li>학교명 : {school.id}</li>
                    <li>선생님 계정 : {school.admName}</li>
                    <li>학생 계정 : {school.stdName}</li>
                    <li>도메인 : @{school.fullDomain}</li>
                    <li>학교 코드 : {school.schoolCode}</li>
                    <li>담당자 : {school.contactName}</li>
                    <li>이메일 : {school.contactEmail}</li>
                    <li>연락처 : {school.contactPhone}</li>
                    <li>교사비번 : {school.teacherPass}</li>
                    <li>학생비번 : {school.studentPass}</li>
                    <li>
                      등록일 :
                      {school.createdAt.toLocaleString("ko-KR", {
                        timeZone: "Asia/Seoul",
                      })}
                    </li>
                  </ol>
                  <ul>
                    <li>교사 : {school.numberOfTeachers}명</li>
                    <li>학생 : {school.numberOfStudents}명</li>
                  </ul>
                </div>
                <button
                  onClick={() =>
                    handleProcessPayments(
                      school.id,
                      school.admName,
                      school.stdName,
                      school.schoolName,
                      school.fullDomain,
                      school.schoolCode,
                      school.contactEmail,
                      school.numberOfTeachers,
                      school.numberOfStudents
                    )
                  }
                  disabled={processing}
                  className="btn btn-primary"
                >
                  {processing ? "처리중" : "결제완료 + 계정생성"}
                </button>
              </div>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default SchoolList;
