import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./SchoolRegistration.css";

const SchoolRegistration = () => {
  const [admName, setAdmName] = useState("admin");
  const [stdName, setStdName] = useState("std");
  const [schoolName, setSchoolName] = useState("");
  const [domainName, setDomainName] = useState("hello");
  const [tld, setTld] = useState("kr");
  const [schoolCode, setSchoolCode] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [numberOfTeachers, setNumberOfTeachers] = useState(2);
  const [numberOfStudents, setNumberOfStudents] = useState(20);
  const [teacherPass, setTeacherPass] = useState("");
  const [studentPass, setStudentPass] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fullDomain = `${domainName}.${tld}`;
    const registrationData = {
      schoolName,
      admName,
      stdName,
      fullDomain,
      schoolCode,
      contactName,
      contactEmail,
      contactPhone,
      numberOfTeachers,
      numberOfStudents,
      teacherPass,
      studentPass,
      createdAt: new Date(),
      pay: false,
    };

    const schoolPass = {
      c: schoolCode,
      s: studentPass,
      t: teacherPass,
      u: "https://app.chocopython.org",
    };

    try {
      const docRef = doc(firestore, "school", schoolName);
      const loginRef = doc(firestore, "login", schoolName);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const existingData = docSnap.data();
        if (existingData.pay) {
          alert("이미 등록 완료되었습니다.\n고객센터: 032-582-0100");
          return;
        }
      }

      if (!registrationData.pay) {
        await setDoc(docRef, registrationData);
        await setDoc(loginRef, schoolPass);
        alert("학교 정보 등록 완료");
        navigate("/success", {
          state: registrationData,
        });
      } else {
        alert("등록 오류 발생했습니다.\n고객센터: 032-582-0100");
      }
    } catch (error) {
      console.error("학교 정보 등록 오류", error);
    }
  };

  function padNumber(base, number) {
    const baseLength = base.length;
    const paddedNumber = number.toString().padStart(baseLength, "0");
    return paddedNumber;
  }

  const generateTeacherSamples = () => {
    let samples = [];
    for (let i = 0; i < 2; i++) {
      samples.push(
        `${admName}${padNumber(
          numberOfTeachers.toString(),
          i + 1
        )}@${domainName}.${tld}`
      );
    }
    return samples;
  };

  const generateStudentSamples = () => {
    let samples = [];
    for (let i = 0; i < 2; i++) {
      samples.push(
        `${stdName}${padNumber(
          numberOfStudents.toString(),
          i + 1
        )}@${domainName}.${tld}`
      );
    }
    return samples;
  };

  return (
    <Container className="container-full-height" style={{ height: "120%" }}>
      <Row className="justify-content-md-center">
        <Col xs={12} md={8} lg={8}>
          <h2>학교 정보 등록</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-1" controlId="formSchoolName">
              <Form.Label>학교 이름</Form.Label>
              <Form.Control
                type="text"
                value={schoolName}
                onChange={(e) => setSchoolName(e.target.value)}
                placeholder="학교 이름을 입력하세요! 예) 헬로고등학교"
                required
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formSchoolCode">
              <Form.Label>학교 코드 (나이스 7자리 숫자)</Form.Label>
              <Form.Control
                type="number"
                value={schoolCode}
                onChange={(e) => setSchoolCode(e.target.value)}
                placeholder="학교 코드를 입력하세요! 예) 7123456"
                required
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formContactName">
              <Form.Label>담당자 이름</Form.Label>
              <Form.Control
                type="text"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                placeholder="담당자 이름을 입력하세요! 예) 홍길동"
                required
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formContactEmail">
              <Form.Label>담당자 이메일</Form.Label>
              <Form.Control
                type="email"
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                placeholder="담당자 이메일을 입력하세요! 예) gildong@hello.kr"
                required
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formContactPhone">
              <Form.Label>담당자 전화번호</Form.Label>
              <Form.Control
                type="tel"
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                placeholder="담당자 연락처를 입력하세요! 예) 010-1234-5678"
                required
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formNumberOfTeachers">
              <Form.Label>교사 계정 수</Form.Label>
              <Form.Control
                type="number"
                value={numberOfTeachers}
                onChange={(e) => setNumberOfTeachers(e.target.value)}
                placeholder="교사 계정 수를 입력하세요!"
                required
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formNumberOfStudents">
              <Form.Label>학생 계정 수</Form.Label>
              <Form.Control
                type="number"
                value={numberOfStudents}
                onChange={(e) => setNumberOfStudents(e.target.value)}
                placeholder="학생 계정 수를 입력하세요."
                required
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formDomain">
              <Form.Label>교사 계정 형식</Form.Label>
              <Row className="row-align">
                <Col xs={3} sm={3} md={3}>
                  <Form.Control
                    type="text"
                    value={admName}
                    onChange={(e) => setAdmName(e.target.value)}
                    placeholder="교사계정명"
                    required
                    className="account-input"
                  />
                </Col>
                <Col xs="auto" className="account-text">
                  {padNumber(numberOfTeachers.toString(), 1)}@
                </Col>
                <Col xs={4}>
                  <Form.Control
                    type="text"
                    value={domainName}
                    onChange={(e) => setDomainName(e.target.value)}
                    placeholder="도메인"
                    required
                    className="account-input"
                  />
                </Col>
                <Col xs="auto" className="account-text">
                  .
                </Col>
                <Col xs={2}>
                  <Form.Control
                    type="text"
                    value={tld}
                    onChange={(e) => setTld(e.target.value)}
                    placeholder="TLD"
                    required
                    className="account-input"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{ fontSize: "16px" }}>예:</p>
                </Col>
                {generateTeacherSamples().map((sample, index) => (
                  <Col key={index}>
                    <p style={{ fontSize: "16px" }}>{sample}</p>
                  </Col>
                ))}
              </Row>
            </Form.Group>
            <Form.Group className="mb-1" controlId="formDomain">
              <Form.Label>학생 계정 형식</Form.Label>
              <Row className="row-align">
                <Col xs={3} sm={3} md={3}>
                  <Form.Control
                    type="text"
                    value={stdName}
                    onChange={(e) => setStdName(e.target.value)}
                    placeholder="학생계정명"
                    required
                    className="account-input"
                  />
                </Col>
                <Col xs="auto" className="account-text">
                  {padNumber(numberOfStudents.toString(), 1)}@
                </Col>
                <Col xs={4}>
                  <Form.Control
                    type="text"
                    value={domainName}
                    onChange={(e) => setDomainName(e.target.value)}
                    placeholder="도메인"
                    required
                    className="account-input"
                  />
                </Col>
                <Col xs="auto" className="account-text">
                  .
                </Col>
                <Col xs={2}>
                  <Form.Control
                    type="text"
                    value={tld}
                    onChange={(e) => setTld(e.target.value)}
                    placeholder="TLD"
                    required
                    className="account-input"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{ fontSize: "16px" }}>예:</p>
                </Col>
                {generateStudentSamples().map((sample, index) => (
                  <Col key={index}>
                    <p style={{ fontSize: "16px" }}>{sample}</p>
                  </Col>
                ))}
              </Row>
            </Form.Group>
            <Form.Group className="mb-1" controlId="formTeacherPass">
              <Form.Label>학교 입장 : 전체 교사용 비밀번호</Form.Label>
              <Form.Control
                type="text"
                value={teacherPass}
                onChange={(e) => setTeacherPass(e.target.value)}
                placeholder="교사용 비밀번호 4자리 숫자를 입력하세요!"
                required
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formStudentPass">
              <Form.Label>학교 입장 : 전체 학생용 비밀번호</Form.Label>
              <Form.Control
                type="text"
                value={studentPass}
                onChange={(e) => setStudentPass(e.target.value)}
                placeholder="학생용 비밀번호 4자리 숫자를 입력하세요!"
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              등록 / 견적서 출력
            </Button>
            <div>
              <br />
              <br />
              <br />
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SchoolRegistration;
