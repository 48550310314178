// src/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { Button } from "react-bootstrap";

function Login({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      onLogin(userCredential.user);
      navigate("/adminhome");
    } catch (err) {
      setError("로그인 실패!");
    }
  };

  return (
    <section className="position-relative overflow-hidden pt-5 pt-lg-3">
      <div align="center">
        <h1 className="text-center">관리자 로그인</h1>

        <div className="tab-content" id="course-pills-tabContent">
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", width: "300px" }}
          >
            {error && <p style={{ color: "red" }}>{error}</p>}
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginBottom: "10px", padding: "8px" }}
              />
            </label>
            <label>
              Password:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginBottom: "20px", padding: "8px" }}
              />
            </label>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              className="card-img-top"
            >
              관리자 로그인
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login;
