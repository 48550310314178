import React, { useState } from "react";
import { auth } from "./firebase";
import { signOut } from "firebase/auth";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Head from "./Head";
import Home from "./school/Home";
import NewSchool from "./school/NewSchool"; /*신규가입 학교홈*/
import Teacher from "./school/Teacher";

import SchoolRegistration from "./school/SchoolRegistration";
import RegistrationSuccess from "./school/RegistrationSuccess";
import SchoolList from "./school/SchoolList";

import AdminHome from "./school/AdminHome";
import Login from "./school/Login";

import logo from "./images/chocopy.svg";
import brand from "./images/logo-CHOCOPYthon-white-400.png";
import logostartc from "./images/logo-startc-white-400.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [user, setUser] = useState(null);

  const handleLogin = (user) => {
    setUser(user);
  };

  const handleLogout = async () => {
    await signOut(auth);
    setUser(null);
  };

  return (
    <Router>
      <Head />
      <div className="App">
        <header className="App-header">
          <a href="https://chocopython.org">
            <img src={brand} className="App-text-logo" alt="logo" />
          </a>
          <img src={logo} className="App-logo" alt="logo" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/newSchool" element={<NewSchool />} />
            <Route path="/schoolReg" element={<SchoolRegistration />} />
            <Route path="/success" element={<RegistrationSuccess />} />
            <Route path="/teacher" element={<Teacher />} />

            <Route path="/schoollist" element={<SchoolList />} />

            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route
              path="/adminhome"
              element={
                user ? (
                  <AdminHome user={user} onLogout={handleLogout} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Routes>
        </header>
        <footer className="App-footer">
          <div>
            <img src={logostartc} className="Footer-logo" alt="startc logo" />
            <p>
              <FontAwesomeIcon icon={faPhone} /> 032-582-0100
            </p>
          </div>
          <p className="Footer-copyright">
            copyright &copy; 2024 스타트씨주식회사. All rights reserved.
          </p>
        </footer>
      </div>
    </Router>
  );
};

export default App;
