import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faSchool,
  faHomeAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const NewSchool = () => {
  return (
    <section className="position-relative overflow-hidden pt-5 pt-lg-3">
      <div align="center">
        <h1 className="text-center">초코파이썬</h1>
        <p className="text-truncate-2 mb-2">학교/단체 신규 가입 안내</p>

        <div className="tab-content" id="course-pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="course-pills-tabs-1"
            role="tabpanel"
            aria-labelledby="course-pills-tab-1"
          >
            <div className="row g-4">
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="card shadow h-100">
                  <Link to="/newSchool" className="mb-3">
                    <Button
                      variant="primary"
                      size="lg"
                      className="card-img-top"
                    >
                      <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                      학교회원 신규가입 안내
                    </Button>

                    <div className="card-body pb-0">
                      <h5 className="card-title fw-normal">
                        학교/단체 등록 방법
                      </h5>
                      <p className="mb-2 text-truncate-2">
                        학교/단체 라이선스 구매 후 계정 생성하고 학습을
                        시작합니다.
                      </p>
                      <ul className="text-primary" align="left">
                        <li>견적서 : 학교 정보 등록</li>
                        <li>결제 : 라이선스 구매</li>
                        <li>계정 : 교사/학생 계정 생성</li>
                        <li>학습 : 초코파이썬 시작</li>
                        <li>동영상 매뉴얼 학습</li>
                      </ul>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="card shadow h-100">
                  <Link to="/schoolReg" className="mb-3">
                    <Button variant="info" size="lg" className="card-img-top">
                      학교 정보 등록
                    </Button>

                    <div className="card-body pb-0">
                      <p className="mb-2 text-truncate-2">
                        계약관련 정보등록과 견적서 출력
                      </p>
                      <ul className="text-primary" align="left">
                        <li>정보등록</li>
                        <li>견적서 저장/출력</li>
                        <li>구매 상담</li>
                        <li>고객센터 : 032-582-0100</li>
                      </ul>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div id="license" className="card shadow h-100">
                  <Link to="/newSchool" className="mb-3">
                    <Button
                      variant="warning"
                      size="lg"
                      className="card-img-top"
                    >
                      라이선스 구매
                    </Button>

                    <div className="card-body pb-0">
                      <p className="mb-2 text-truncate-2">
                        계약서 작성, 결제, 전자세금계산서
                      </p>
                      <ul className="text-primary" align="left">
                        <li>계약서 작성</li>
                        <li>결제 : 라이선스 구매</li>
                        <li>전자세금계산서 발행</li>
                        <li>고객센터 : 032-582-0100</li>
                      </ul>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="card shadow h-100">
                  <Link to="/newSchool" className="mb-3">
                    <Button
                      variant="secondary"
                      size="lg"
                      className="card-img-top"
                    >
                      교사/학생 계정 생성
                    </Button>

                    <div className="card-body pb-0">
                      <p className="mb-2 text-truncate-2">
                        교사 일괄 생성 및 학생 개별 생성 모두 지원
                      </p>
                      <ul className="text-primary" align="left">
                        <li>1 ~ 30계정 : 직접생성가능</li>
                        <li>30 계정 이상 : 고객센터지원</li>
                        <li>신청접수 후 바로 생성</li>
                        <li>고객센터 : 032-582-0100</li>
                      </ul>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="card shadow h-100">
                  <Link to="/" className="mb-3">
                    <Button
                      variant="success"
                      size="lg"
                      className="card-img-top"
                    >
                      <FontAwesomeIcon icon={faSchool} className="me-2" />
                      학교수업시작 (교사, 학생)
                    </Button>
                    <div>
                      <img
                        src="assets/images/saas/c001.png"
                        className="card-img-top"
                        alt="course image"
                      />
                    </div>
                    <div className="card-body pb-0">
                      <div className="d-flex justify-content-between mb-2">
                        <a
                          href="/"
                          className="badge bg-success bg-opacity-10 text-success"
                        >
                          초/중/고/대학
                        </a>
                        <a href="/" className="text-danger">
                          <i className="fas fa-heart"></i>
                        </a>
                      </div>

                      <h5 className="card-title fw-normal">초코파이썬 등교</h5>
                      <div className="card-body pb-0">
                        <ul className="text-primary" align="left">
                          [학생]
                          <li>학교 공통 : 학생 로그인</li>
                          <li>초코파이썬 실행하기</li>
                        </ul>

                        <ul className="text-primary" align="left">
                          [교사]
                          <li>학교 공통 : 교사 로그인</li>
                          <li>초코파이썬 실행하기</li>
                          <li>학생 추가 등록</li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="card shadow h-100">
                  <Link to="https://chocopython.org" className="mb-3">
                    <Button variant="danger" size="lg" className="card-img-top">
                      <FontAwesomeIcon icon={faHomeAlt} className="me-2" />
                      홈페이지
                    </Button>
                    <div>
                      <img
                        src="assets/images/saas/homepage.png"
                        className="card-img-top"
                        alt="homepage image"
                        border="1px"
                      />
                    </div>
                    <div className="card-body pb-0">
                      <p className="mb-2 text-truncate-2">
                        초코파이썬 정보를 제공합니다.
                      </p>
                      <ul className="text-danger" align="left">
                        <li>동영상 매뉴얼</li>
                        <li>
                          개인학습자 사용권구매{" "}
                          <i className="fas fa-shopping-cart text-orange me-2"></i>
                        </li>
                      </ul>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="card shadow h-100">
                  <Link to="tel:032-582-0100" className="mb-3">
                    <Button
                      variant="primary"
                      size="lg"
                      className="card-img-top"
                    >
                      고객센터
                      <br />
                      <p>
                        <FontAwesomeIcon icon={faPhone} /> 032-582-0100
                      </p>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewSchool;
