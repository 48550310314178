import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faSchool,
  faLaptop,
  faHomeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const AdminHome = () => {
  return (
    <section className="position-relative overflow-hidden pt-5 pt-lg-3">
      <div align="center">
        <h1 className="text-center">StartC : 관리자 화면</h1>

        <div className="tab-content" id="course-pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="course-pills-tabs-1"
            role="tabpanel"
            aria-labelledby="course-pills-tab-1"
          >
            <div className="row g-4">
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="card shadow h-100">
                  <Link to="/schoollist" className="mb-3">
                    <Button
                      variant="primary"
                      size="lg"
                      className="card-img-top"
                    >
                      <FontAwesomeIcon icon={faSchool} className="me-2" />
                      학교 정보 등록 목록 조회
                    </Button>

                    <div className="card-body pb-0">
                      <div className="d-flex justify-content-between mb-2">
                        <a
                          href=""
                          className="badge bg-purple bg-opacity-10 text-purple"
                        >
                          All level
                        </a>
                        <a href="" className="h6 mb-0">
                          <i className="far fa-heart"></i>
                        </a>
                      </div>

                      <h5 className="card-title fw-normal">
                        <a href="">학교 라이선스 계약</a>
                      </h5>
                      <p className="mb-2 text-truncate-2">
                        학교 구매 담당자 / 교사
                      </p>
                      <ol className="text-primary" align="left">
                        <li>학교 정보 등록 : 교사</li>
                        <li>견적서 : 온라인 pdf</li>
                        <li>결제 : 구매 담당자</li>
                        <li>계정 생성 : StartC 고객센터</li>
                      </ol>
                    </div>
                    <div className="card-footer pt-0 pb-3">
                      <hr />
                      <div className="d-flex justify-content-between ">
                        <span className="h6 fw-light mb-0">
                          <i className="fas fa-shopping-cart text-orange me-2"></i>
                          개인학습자 사용권구매 Shop
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="card shadow h-100">
                  <Link to="/schoollist" className="mb-3">
                    <Button
                      variant="primary"
                      size="lg"
                      className="card-img-top"
                    >
                      <FontAwesomeIcon icon={faSchool} className="me-2" />
                      학교 정보 등록 목록 조회
                    </Button>

                    <div className="card-body pb-0">
                      <div className="d-flex justify-content-between mb-2">
                        <a
                          href=""
                          className="badge bg-purple bg-opacity-10 text-purple"
                        >
                          All level
                        </a>
                        <a href="" className="h6 mb-0">
                          <i className="far fa-heart"></i>
                        </a>
                      </div>

                      <h5 className="card-title fw-normal">
                        <a href="">학교 라이선스 계약</a>
                      </h5>
                      <p className="mb-2 text-truncate-2">
                        학교 구매 담당자 / 교사
                      </p>
                      <ol className="text-primary" align="left">
                        <li>학교 정보 등록 : 교사</li>
                        <li>견적서 : 온라인 pdf</li>
                        <li>결제 : 구매 담당자</li>
                        <li>계정 생성 : StartC 고객센터</li>
                      </ol>
                    </div>
                    <div className="card-footer pt-0 pb-3">
                      <hr />
                      <div className="d-flex justify-content-between ">
                        <span className="h6 fw-light mb-0">
                          <i className="fas fa-shopping-cart text-orange me-2"></i>
                          개인학습자 사용권구매 Shop
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="card shadow h-100">
                  <Link to="/schoollist" className="mb-3">
                    <Button
                      variant="primary"
                      size="lg"
                      className="card-img-top"
                    >
                      <FontAwesomeIcon icon={faSchool} className="me-2" />
                      학교 정보 등록 목록 조회
                    </Button>

                    <div className="card-body pb-0">
                      <div className="d-flex justify-content-between mb-2">
                        <a
                          href=""
                          className="badge bg-purple bg-opacity-10 text-purple"
                        >
                          All level
                        </a>
                        <a href="" className="h6 mb-0">
                          <i className="far fa-heart"></i>
                        </a>
                      </div>

                      <h5 className="card-title fw-normal">
                        <a href="">학교 라이선스 계약</a>
                      </h5>
                      <p className="mb-2 text-truncate-2">
                        학교 구매 담당자 / 교사
                      </p>
                      <ol className="text-primary" align="left">
                        <li>학교 정보 등록 : 교사</li>
                        <li>견적서 : 온라인 pdf</li>
                        <li>결제 : 구매 담당자</li>
                        <li>계정 생성 : StartC 고객센터</li>
                      </ol>
                    </div>
                    <div className="card-footer pt-0 pb-3">
                      <hr />
                      <div className="d-flex justify-content-between ">
                        <span className="h6 fw-light mb-0">
                          <i className="fas fa-shopping-cart text-orange me-2"></i>
                          개인학습자 사용권구매 Shop
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="card shadow h-100">
                  <Link to="/schoollist" className="mb-3">
                    <Button
                      variant="primary"
                      size="lg"
                      className="card-img-top"
                    >
                      <FontAwesomeIcon icon={faSchool} className="me-2" />
                      학교 정보 등록 목록 조회
                    </Button>

                    <div className="card-body pb-0">
                      <div className="d-flex justify-content-between mb-2">
                        <a
                          href=""
                          className="badge bg-purple bg-opacity-10 text-purple"
                        >
                          All level
                        </a>
                        <a href="" className="h6 mb-0">
                          <i className="far fa-heart"></i>
                        </a>
                      </div>

                      <h5 className="card-title fw-normal">
                        <a href="">학교 라이선스 계약</a>
                      </h5>
                      <p className="mb-2 text-truncate-2">
                        학교 구매 담당자 / 교사
                      </p>
                      <ol className="text-primary" align="left">
                        <li>학교 정보 등록 : 교사</li>
                        <li>견적서 : 온라인 pdf</li>
                        <li>결제 : 구매 담당자</li>
                        <li>계정 생성 : StartC 고객센터</li>
                      </ol>
                    </div>
                    <div className="card-footer pt-0 pb-3">
                      <hr />
                      <div className="d-flex justify-content-between ">
                        <span className="h6 fw-light mb-0">
                          <i className="fas fa-shopping-cart text-orange me-2"></i>
                          개인학습자 사용권구매 Shop
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminHome;
