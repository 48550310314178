// Head.js
import React from "react";
import { Helmet } from "react-helmet";

const Head = () => {
  return (
    <Helmet>
      <link
        id="style-switch"
        rel="stylesheet"
        type="text/css"
        href="assets/css/style.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="./assets/vendor/font-awesome/css/all.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="./assets/vendor/bootstrap-icons/bootstrap-icons.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="./assets/vendor/tiny-slider/tiny-slider.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="./assets/vendor/glightbox/css/glightbox.css"
      />
    </Helmet>
  );
};

export default Head;
