import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { firestore } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { Form, Button } from "react-bootstrap";
import "./School.css";

const Home = () => {
  const [schoolName, setSchoolName] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (role) => {
    try {
      const docRef = doc(firestore, "login", schoolName);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const isValidPassword =
          role === "Teacher" ? data.t === password : data.s === password;

        if (isValidPassword) {
          console.log(`login successful` + schoolName);

          if (role === "Teacher" && schoolName === "a") {
            navigate(`/schoollist`, {
              state: {
                schoolName,
                schoolCode: data.c,
                schoolUrl: data.u,
              },
            });
          } else if (role === "Teacher") {
            navigate(`/teacher`, {
              state: {
                schoolName,
                schoolCode: data.c,
                schoolUrl: data.u,
              },
            });
          } else {
            window.open(data.u, "_blank");
          }
        } else {
          console.error("Login failed: Invalid password");
          alert("비밀번호가 올바르지 않습니다.");
        }
      } else {
        console.error("Login failed: School not found");
        alert("학교를 찾을 수 없습니다.");
      }
    } catch (error) {
      console.error("Error fetching school data:", error);
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    }
  };

  return (
    <section className="position-relative overflow-hidden pt-5 pt-lg-3">
      <div align="center">
        <h1 className="text-center">초코파이썬</h1>
        <p className="text-truncate-2 mb-2">학교별 프로그램 실행</p>
        <div className="teacher-container">
          <div className="card">
            <Form>
              <Form.Group controlId="schoolName" className="form-group">
                <Form.Label>학교이름</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="학교이름"
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                  className="form-control"
                />
              </Form.Group>
              <Form.Group controlId="password" className="form-group">
                <Form.Label>비밀번호</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="비밀번호"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                />
              </Form.Group>
              <div className="button-group">
                <Button
                  variant="primary"
                  className="button"
                  onClick={() => handleLogin("Teacher")}
                >
                  교사
                </Button>
                <Button
                  variant="success"
                  className="button"
                  onClick={() => handleLogin("Student")}
                >
                  학생
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="card">
          <Link to="/newschool" className="mb-3">
            <Button variant="info" size="lg" className="card-img-top">
              신규 학교 등록 / 상담
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Home;
