import React from "react";
import { useLocation } from "react-router-dom";
import "./School.css";

const Teacher = () => {
  const location = useLocation();
  const {
    schoolName = "Unknown School",
    schoolCode = "",
    schoolUrl = "",
  } = location.state || {};

  return (
    <div className="teacher-container">
      <div className="card">
        <h1 className="card-title">
          {schoolName} {schoolCode}
        </h1>
        <div className="card-body">
          <p className="card-text">학습사이트 : {schoolUrl}</p>
          <a
            href={schoolUrl}
            className="btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            초코파이썬 실행
          </a>
        </div>
      </div>
      <div className="card">
        <h1 className="card-title">학생 계정 추가 등록</h1>
        <div className="card-body">
          <p className="card-text">
            등록 가능한 라이선스 계정수가 있어야 합니다.
          </p>
          <ol align="left">
            <li>라이선스 수량 확인</li>
            <li>학교코드7자리 확인 예시) 1234567</li>
            <li>
              팀코드 확인
              <ul>
                <li>기본 : 000</li>
                <li>학년반 나눈 경우 예시) 5학년 1반 : 501</li>
              </ul>
            </li>
            <li>
              초코파이썬 실행 후 회원가입
              <ul>
                <li>학생정보 등록</li>
                <li>Group No.에 #학교코드 입력 예시) #1234567</li>
                <li>Team No.에 팀코드 000 입력</li>
              </ul>
            </li>
            <li>교사계정 로그인</li>
            <ul>
              <li>학생계정 추가등록 승인</li>
            </ul>
            <li>학생 계정 로그인</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Teacher;
